<template>
    <div class="application-form">
        <h6>Configure Google Authenticator or Authy</h6>
        <hr>
        <p>Connect an authenticator app that generates verification codes. You can use codes when we need to verify your identity.</p>
        <div class="mb-4">
            <ol>
                <li>Download and install an authenticator app on your mobile device.</li>
                <li>Use the app to scan this QR code.</li>
                <li>Enter the code generated by the app.</li>
            </ol>
        </div>
        <h6>Scan QR code:</h6>
        <hr>
        <p>You can scan the following QR code or enter the code manually in your app:</p>
        <div style="text-align: center">
            <img :src="this.qrCode.url" alt="QR Code">
        </div>
        <h5 class="text-center mb-4">{{ this.qrCode.secret }}</h5>
        <h6>Verify Your Code</h6>
        <hr>
        <p>Enter the code generated by your application.</p>
        <ui-input 
            class="me-2"
            id="code"
            name="code"
            label="Code"
            type="text"
            placeholder="Enter code"
            v-model:value="formData.token"
            :field="v$.formData.token"
        />
    </div>
</template>

<script>
import QR from 'qrcode';
import { useVuelidate } from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';

import UiInput from '@/components/ui/Input';

export default {
    name: 'ApplicationForm',
    components: { UiInput },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            formData: {
                token: '',
                otp_url: '',
            },
            qrCode: {
                url: '',
                secret: '',
            },
        };
    },
    validations() {
        return {
            formData: {
                token: {
                    required: helpers.withMessage('The code is required.', required),
                    $autoDirty: true,
                },
            },
        };
    },
    methods: {
        clearForm() {
            this.formData.token = '';
            this.formData.otp_url = '';
            this.v$.$reset();
        },
        async validate() {
           return await this.v$.$validate();
        },
        async generateOtp() {
            try {
                const { data } = await this.$axios.get('/_/otp/generate');
                const { otp_url } = data;

                this.qrCode.url = await QR.toDataURL(otp_url);
                this.qrCode.secret = new URL(data.otp_url).searchParams.get('secret');
                this.formData.otp_url = otp_url;
            } catch(error){
                this.$toast.error(this.getErrorMessage(error));
            }
        },
    },
};
</script>
